@import 'libs/uikit/src/styles/global';

.container {
  &:global(.ant-btn) {
    display: flex;
    border-radius: 4px;
    border: 2px solid var(--color-greyscale-400);
    padding: 10px;
    height: 44px;
    justify-content: center;
    box-shadow: none;

    &:hover {
      border-color: var(--color-greyscale-400) !important;
    }
  }

  .icon {
    color: var(--color-greyscale-400);

    &.filled {
      color: var(--color-yellow-200);
    }
  }

  &.disabled {
    background-color: var(--color-greyscale-200) !important;
  }
}

.favorite-opacity {
  opacity: 0.5;
}
