@import 'libs/uikit/src/styles/global';

.container {
  .btn {
    display: flex;
    border-radius: 4px;
    padding: 0;
    height: 44px;
    padding: 10px;
    border: 2px solid var(--color-greyscale-400);
    color: var(--color-greyscale-400);

    &:hover {
      color: var(--color-greyscale-400) !important;
      border-color: var(--color-greyscale-400) !important;
    }
  }

  .filter-dropdown-container {
    background-color: var(--color-white);
    border-radius: 8px;
    width: calc(100vw - 32px);
    max-width: 17.5rem;
    right: -48px;
    box-shadow: 0 4px 8px rgb(42 42 46 / 15%);
    right: 0;
  }

  :global(.ant-drawer-content-wrapper) {
    height: calc(100vh - 180px) !important;
  }

  .drawer-container {
    :global(.ant-drawer-header) {
      padding: 16px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: rem(20px);
        font-weight: 500;
      }

      .clear {
        font-size: rem(16px);
        font-weight: normal;
      }
    }

    :global(.ant-drawer-body) {
      padding: 0;
    }
  }
}
