@import 'libs/uikit/src/styles/global';

.container {
  .images-container {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    max-width: 600px;
    margin: auto;
    margin-bottom: 32px;
    justify-content: space-between;

    @include media('>md') {
      display: flex;
      justify-content: space-between;
    }

    .items {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100px;

      @include media('>md') {
        margin: unset;
      }

      .img {
        height: auto;
        width: 100px;
        margin-bottom: 8px;
      }

      .title {
        font-size: rem(20px);
        color: var(--color-greyscale-600);
        line-height: rem(20px);
      }

      .sub-title {
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-greyscale-400);
      }
    }
  }

  .content-container {
    width: 100%;
    margin-top: 8px;

    li {
      padding-left: 10px;

      a {
        text-decoration: underline;
      }
    }
  }

  ul {
    padding-inline-start: 20px;
  }
}
