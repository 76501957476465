@import 'libs/uikit/src/styles/global';

.container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;

  .image-container {
    width: 120px;
    height: 86px;
    border-radius: 22%;
    margin-right: 5px;

    .image {
      border-radius: 22%;

      &.image-under-construction {
        filter: grayscale(100%);
        opacity: 0.2;
        z-index: -1;
      }
    }

    .under-construction {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: -86px;
      border-radius: 22%;
      background-color: rgba(197, 197, 197, 0.2);
      height: 86px;
      width: 86px;
      z-index: 2;

      & > span {
        color: var(--color-greyscale-500);
        font-size: rem(18px);
        font-weight: 500;
      }
    }
  }

  .action {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-size: rem(20px);

    .detail-wrapper {
      display: flex;

      .title {
        font-weight: 700;
        font-size: rem(24px);
        line-height: rem(28px);

        & > .list-style {
          color: var(--color-primary-1);
          margin-right: 5px;
        }
      }
    }
    .top {
      display: flex;
      justify-content: space-between;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      padding-left: 14px;
    }

    .icon {
      color: var(--color-greyscale-400);
      font-size: rem(20px);
      cursor: pointer;
      margin-right: 10px;
    }

    .favorite {
      color: var(--color-yellow-200);
    }

    .disabled {
      color: var(--color-greyscale-200);
      cursor: default;
    }

    .btn {
      height: 5ch;
      padding: 0px;
      width: 103px;
      margin-top: 16px;
    }
  }
}
