@import 'libs/uikit/src/styles/global';

.section-extra {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  border-radius: 50%;
  padding: 5px;
  font-size: rem(11px);
  width: 34px;
  height: 34px;
  color: var(--color-blue-200);
}

.container {
  padding: 0 8px;

  .under-construction {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 0;
    height: 153px;
    margin-bottom: -153px;
    background-color: rgba(197, 197, 197, 0.2);
    border-radius: 8px;
    z-index: 1;

    @include media('>md') {
      height: 200px;
      margin-bottom: -200px;
    }

    & > span {
      color: var(--color-greyscale-500);
      font-size: rem(32px);
      font-weight: 500;
    }
  }

  .card {
    height: 153px;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--color-greyscale-200);
    position: relative;

    @include media('>md') {
      height: 200px;
    }

    &.image-under-construction {
      filter: grayscale(100%);
      opacity: 0.2;
    }
  }

  .champion-card {
    padding: 6px;
    height: 100px;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--color-violet-100);
    position: relative;

    .champion-body {
      align-items: center;
      display: flex;
      height: 100%;

      .body {
        margin-left: 4px;

        .username {
          color: var(--color-greyscale-400);
          font-size: 16px;
        }

        .title {
          font-weight: bold;
          color: var(--color-primary-1);
          font-size: 20px;
        }

        .subtitle {
          color: var(--color-violet-400);
          font-size: 18px;
        }

        .link {
          text-decoration: underline;
          font-size: 18px;
        }

        .description {
          font-weight: bold;
          font-size: 18px;
        }

        span {
          font-size: 18px;
        }

        p {
          margin: 0;
          line-height: 1;
        }
      }
    }

    @include media('>md') {
      height: 110px;
    }
  }

  .favorite {
    position: absolute;
    top: 12px;
    left: 12px;
    color: var(--color-greyscale-400);
    cursor: pointer;
    z-index: 2;
  }

  .active {
    color: var(--color-yellow-200);
  }

  .live {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9px;
    top: 11px;
    right: 11px;
    z-index: 2;

    .sound {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      padding: 2px 6px;
      background: rgba(69, 69, 69, 0.78);
      gap: 4px;
      border-radius: 10px;
      z-index: 2;

      .sound-icon {
        color: var(--color-white);
        width: 9px;
        height: 8.5px;
      }

      .language {
        color: var(--color-white);
        font-size: 22px;
        line-height: 22px;
        cursor: default;
      }

      .global-icon {
        color: var(--color-white);
        width: 12px;
        height: 12px;
      }
    }
  }

  .people {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 12px;
    bottom: 60px;
    z-index: 1;
    gap: 6px;

    .panel {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 6px;
      background: rgba(69, 69, 69, 0.78);
      gap: 4px;
      border-radius: 10px;

      .people-icon {
        color: var(--color-white);
        width: 12px;
        height: 11px;
      }

      .text {
        color: var(--color-white);
        font-size: 22px;
        line-height: 22px;
        cursor: default;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 3px;
    min-height: 50px;

    .game-name {
      margin: 0;
      font-size: $--font-size-base;
      font-weight: 500;
      width: 260px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include media('<md') {
        width: 190px;
      }
    }

    .button {
      height: 36px;
      padding: 5px 16px;

      span {
        font-weight: 500;
      }
    }
  }
}
