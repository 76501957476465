@import 'libs/uikit/src/styles/global';

.section-extra {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  border-radius: 50%;
  padding: 5px;
  font-size: rem(11px);
  width: 34px;
  height: 34px;
  color: var(--color-blue-200);
}

.promotion-section {
  & :global(.title) {
    width: 100%;
    max-width: 60%;
    line-height: 0.8;
    margin-top: 8px;
  }
}

.container {
  position: relative;
  .arrow-right,
  .arrow-left {
    color: var(--color-greyscale-100) !important;
    &::after {
      font-size: 28px !important;
    }
  }
  .swiper-container {
    border-radius: 8px;

    :global(.swiper-pagination) {
      position: initial;
      margin-top: 14px;
      line-height: 6px;
      min-height: 9px;

      :global(.swiper-pagination-bullet) {
        background-color: #b6b6b6;
      }

      :global(.swiper-pagination-bullet-active) {
        background-color: #707070;
      }
    }

    .slide {
      text-align: center;

      .img {
        width: 100%;
        border-radius: 8px;
        cursor: pointer;
        object-fit: cover;
        aspect-ratio: 18 / 5 !important;
        @include media('>md') {
          aspect-ratio: 10 / 3;
        }
      }
    }
  }
}
