@import 'libs/uikit/src/styles/global';

.loading-container,
.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  font-size: rem(42px);
}

.list-container {
  display: grid;
  grid-template-columns: 100%;
  column-gap: 16px;
  width: 100%;

  @include media('>md') {
    grid-template-columns: repeat(2, 1fr);
  }

  .divider {
    margin: 20px 0;
  }
}

.list-providers-container {
  display: grid;
  grid-template-columns: 100%;
  gap: 18px;
  margin-bottom: 50px;

  @include media('>md') {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 30px;
  }

  .card-wrapper {
    width: 100%;

    .new-icon {
      position: absolute;
      width: 40px;
      height: 16px;
    }

    .card-box {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid var(--color-greyscale-200);
      padding: 20px 56px;
      width: 100%;
      height: 123px;
      cursor: pointer;

      @include media('>md') {
        height: 167px;
      }

      .image {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;

        &.image-under-construction {
          filter: grayscale(100%);
          opacity: 0.1;
        }
      }
    }

    .under-construction {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.8);
      height: 121px;
      width: calc(100% - 2px);
      margin-left: 1px;
      margin-top: -122px;
      border-radius: 8px;

      @include media('>md') {
        height: 165px;
        margin-top: -166px;
      }

      & > span {
        color: var(--color-greyscale-500);
      }
    }
  }
}
