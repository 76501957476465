@import 'libs/uikit/src/styles/global';

.form {
  width: 100%;

  .search-icon {
    font-size: rem(20px);
    margin-right: 10px;
  }
}
