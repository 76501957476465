@import 'libs/uikit/src/styles/global';

.section {
  padding: 0 16px;
  margin-bottom: 32px;

  .header {
    margin-top: -16px;
    margin-bottom: 16px;
  }

  .body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    @include media('>md') {
      display: flex;
      justify-content: space-between;
    }

    .card-wrapper {
      width: 100%;

      .new-icon {
        position: absolute;
        width: 40px;
        height: 16px;
      }

      .card-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid var(--color-greyscale-200);
        padding: 15px;
        height: 70px;
        width: 100%;
        cursor: pointer;

        @include media('>md') {
          width: 100%;
        }

        .image {
          width: auto;
          min-width: 0px;
          max-width: 100%;
          height: auto;
          max-height: 100%;

          &.image-under-construction {
            cursor: not-allowed;
            filter: grayscale(100%);
            opacity: 0.2;
          }
        }
      }

      .under-construction {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.8);
        height: 68px;
        width: calc(100% - 2px);
        margin-left: 1px;
        margin-top: -69px;
        border-radius: 8px;
        cursor: not-allowed;

        & > span {
          color: var(--color-greyscale-500);
        }
      }
    }
  }
}
