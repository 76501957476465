@import 'libs/uikit/src/styles/global';

.container {
  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 12px 16px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    .title {
      font-weight: 400;
    }

    .clear {
      text-decoration-line: underline;
      font-size: 0.6666666667rem;
      color: #7f7f82;
      cursor: pointer;
    }
  }

  .form {
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .body {
    padding: 16px;

    .category-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 36px;

      .form-control {
        width: 100%;
      }

      .category {
        margin-bottom: 10px;
        font-size: rem(20px);
        line-height: 20px;
        font-weight: 500;
      }

      .dealer-group-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .game-type-group-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        gap: 10px;
      }
    }
  }

  .footer {
    padding: 10px 36px;
  }
}
